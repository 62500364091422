import {
  OnInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';
import { getCurrencySymbol } from '@angular/common';
import { Subject, Subscription } from 'rxjs';
import _ from 'lodash';
import {
  ArrayConversions,
  Company,
  CompanyKey,
  DynamicChoiceKey,
  EditItemType,
  LocalizedTextIds,
  ReferenceValue,
  RoundsBySector,
  TagWithCount,
  UpdateOptions,
  getUpdateOptions,
} from 'company-finder-common';

import {
  MultiChoiceItemChange,
  SelfUpdateMode,
  ReviewableUpdateField,
  TagReviewModification,
  TagReviewModificationType,
  EditItemChoice,
} from '../../company-update.interface';

import { LogoSize } from '../../../../_common/constants/LogoSize';
import { ReviewEditsService } from '../../services/review-edits.service';
import {
  TagsPickerModalComponent,
  TagsPickerMode,
} from '../../../../_common/components/picker-modal/tags-picker-modal.component';
import { CompanyService } from '../../../../_common/services/company/company.service';
import { CompanyUpdateService } from '../../services/company-update.service';
import { UpdateComponentBase } from '../../UpdateComponentBase';
import { DeploymentContext } from '../../../../_common/utilities/deployment-context/deployment-context';
import { areObjectsDifferent } from '../../utils/diff';

// TODO: Externalize maxUploadLogoImageSize to config file if desired
// Note: if request payload goes over current limit we set at 10mb,
// need to increase bodyParser.json.limit and bodyParser.urlencoded.limit in server.ts
// Current Salesforce limit is 128KB of base64 text (= 96KB of binary).  They will downsample
// files larger than that, but only up to 1 MB, and we have seen this process fail, so limit
// to 96KB.
const maxUploadLogoImageSize = 96 * 1024; // 96kb

@Component({
  selector: 'edit-item',
  templateUrl: './edit-item.component.html',
  styleUrls: ['./edit-item.component.scss'],
})
export class EditItemComponent
  extends UpdateComponentBase
  implements OnInit, OnDestroy
{
  public constructor(
    dc: DeploymentContext,
    private _reviewEditsService: ReviewEditsService,
    _companyUpdateService: CompanyUpdateService,
    _companyService: CompanyService
  ) {
    super(dc, _companyUpdateService, _companyService);
  }

  public updateOptions: UpdateOptions;

  public async ngOnInit(): Promise<void> {
    this.updateOptions = getUpdateOptions(this.propertyName);

    if (this.updateOptions.editType === this.EditItemType.Tags) {
      this.existingTags = (await this._companyService.getTags())?.map((tag) =>
        tag.toLowerCase()
      );
    }
    // If this isn't a control that we manage the length on, no point registering a subscription
    if (this.canLimitLength) {
      this.lengthSubscription = this.formControl?.valueChanges.subscribe(
        (value) => {
          this.currentLength = value?.length ?? 0;
        }
      );
    }
  }

  public async ngOnDestroy(): Promise<void> {
    this.lengthSubscription?.unsubscribe();
  }

  public get currencySymbol(): string {
    return this.currency
      ? getCurrencySymbol(this.currency, 'narrow')
      : getCurrencySymbol('USD', 'narrow');
  }

  public get id(): string {
    return `company-update-${this.propertyName}`;
  }

  public get isReviewMode(): boolean {
    return this.selfUpdateMode === SelfUpdateMode.Review;
  }

  public get isThisUpdateFieldSet(): boolean {
    return this.isUpdateFieldSet(this.propertyName);
  }

  public get isEditingThisProperty(): boolean {
    return this.isEditingProperty[this.propertyName];
  }

  public get showApproveDecline(): boolean {
    return this.isReviewMode && this.isThisUpdateFieldSet;
  }

  public get isAvailableToEdit(): boolean {
    if (
      this.isReadOnly ||
      (this.selfUpdateMode === SelfUpdateMode.Review &&
        this.type === EditItemType.Tags)
    ) {
      return false;
    }

    return (
      this.selfUpdateMode === SelfUpdateMode.Edit ||
      (this.selfUpdateMode === SelfUpdateMode.Review &&
        this.isReviewableUpdateFieldSet &&
        !this.isPropertyDeclined)
    );
  }

  public get isEmptyValue(): boolean {
    return (
      this.company[this.propertyName] === null ||
      this.company[this.propertyName] === undefined ||
      this.company[this.propertyName] === ''
    );
  }

  public get isInactive(): boolean {
    return (
      this._reviewEditsService.currentEditItemProperty &&
      this._reviewEditsService.currentEditItemProperty !== this.propertyName
    );
  }

  public get isPropertyDeclined(): boolean {
    return (
      !this.isReadOnly &&
      this._reviewEditsService.isPropertyDeclined(this.propertyName)
    );
  }

  public get isReviewableUpdateFieldSet(): boolean {
    return (
      this.reviewableUpdateFields &&
      (this.isThisUpdateFieldSet || this.hasPendingMultiBoolChanges)
    );
  }

  public get showFlagIcon(): boolean {
    return (
      this.isReviewableUpdateFieldSet &&
      this._reviewEditsService.isPropertyInNeedOfReview(this.propertyName)
    );
  }

  public get showRevertIcon(): boolean {
    // NOTE: Tag reversion is handled differently, and does not show the revert icon.
    const companyToCompare =
      this.selfUpdateMode === SelfUpdateMode.Edit &&
      !this.reviewableUpdateFields
        ? this.companyBeforeAnyChanges
        : this.companyWithPending;
    const isItemGroup = this.propertyName === 'firstTimeEntrepreneur';
    return (
      this.isAvailableToEdit &&
      (this.isEditingProperty[this.propertyName] ||
        this.itemIsDifferent(this.propertyName, companyToCompare, isItemGroup))
    );
  }

  public get reviewableUpdateFields(): ReviewableUpdateField[] {
    return this.updateForReview.updateFields;
  }

  public get showViewOriginalButton(): boolean {
    return (
      !this.isEditingProperty[this.propertyName] &&
      !this.isReadOnly &&
      this.type !== EditItemType.Logo &&
      this.type !== EditItemType.MultiBoolean &&
      this.type !== EditItemType.Tags &&
      this.isReviewableUpdateFieldSet
    );
  }

  public get originalValue(): string {
    return this.companyBeforeAnyChanges[this.propertyName] ?? '';
  }

  public get originalValueDisplay(): string {
    return this.originalValue.length > 0
      ? this.originalValue
      : this.Loc(LocalizedTextIds.NoPreviousValue);
  }

  public get tags(): string[] {
    return this.company.tags;
  }

  public get tagsAdded(): string[] {
    return _.difference(this.company.tags, this.companyBeforeAnyChanges.tags);
  }

  public get customTagsAdded(): string[] {
    return this.tagsAdded.filter((tag) => this.isCustomTag(tag));
  }

  public get existingTagsAdded(): string[] {
    return this.tagsAdded.filter((tag) => !this.isCustomTag(tag));
  }

  public get tagsRemoved(): string[] {
    return _.difference(this.companyBeforeAnyChanges.tags, this.company.tags);
  }

  public get tagsUnchanged(): string[] {
    return _.intersection(this.company.tags, this.companyBeforeAnyChanges.tags);
  }

  // private getters
  private get hasPendingMultiBoolChanges(): boolean {
    // NOTE: We should only have to discover this once, but we haven't been able to get it
    //       to work in ngOnInit or even ngOnChanges. Ideally ngOnInit would check, set, and
    //       move on, but even though the company object looks correct, the individual properties
    //       of interest are not reporting accurately. Checking inside a setTimeout works
    //       intermittently, but the size of the timeout required varies, so is not reliable.
    //       This comment & approach, can be removed once the referenced JIRA ticket is resolved.
    // Reference: https://jira.jnj.com/browse/ADJQ-474
    return (
      this.reviewableUpdateFields &&
      this.type === EditItemType.MultiBoolean &&
      // The only exception to the valueList being a ReferenceValue array is the current round stage,
      // which is not a multibool. Ideally, it would be a ReferenceValue array, but with it being dynamic
      // based on the config and sector, it has not been reworked
      (this.valueList as ReferenceValue[])?.some((editItem) =>
        this.isUpdateFieldSet(editItem.value)
      )
    );
  }

  public existingTags: string[];
  public isShowingOriginalText = false;

  public get labelText(): string {
    return this.getHeaderForProperty(this.propertyName);
  }

  public get hidden(): boolean {
    return !!this.updateOptions.preconditionProp
      ? !this.company[this.updateOptions.preconditionProp]
      : false;
  }

  public get labelDetail(): string {
    return this.Loc(this.updateOptions.labelTextId);
  }

  public get prompt(): string {
    return this.Loc(this.updateOptions.promptTextId);
  }

  public get footnote(): string {
    return this.Loc(this.updateOptions.footnoteId);
  }

  public get showFootnote(): boolean {
    return (
      this.updateOptions.footnoteCountries?.includes(
        this.company.countryForDeiReporting
      ) && !!this.footnote
    );
  }

  public get tooltip(): string {
    return this.Loc(this.updateOptions.tooltipId);
  }

  public get subtitle(): string {
    return this.Loc(this.updateOptions.subtitleId);
  }

  public get type(): EditItemType {
    return this.updateOptions.editType ?? EditItemType.Default;
  }

  public get isNumericType(): boolean {
    return [
      EditItemType.Numeric,
      EditItemType.Year,
      EditItemType.Currency,
    ].includes(this.type);
  }

  public get currency(): string {
    return 'USD';
  }

  public get maxLength(): number {
    if (this.type === EditItemType.Year) {
      return 4;
    }
    return this.updateOptions.maxLength ?? 32768;
  }

  public get max(): number {
    if (this.type === EditItemType.Year) {
      return 9999;
    }
  }

  public get step(): number | undefined {
    return this.type === EditItemType.Year ? 1 : undefined;
  }

  public get pattern(): string | undefined {
    return this.type === EditItemType.Year ? '\\d*' : undefined;
  }

  public get showLength(): boolean {
    return this.updateOptions.showLength;
  }

  public get isJnjConfidentialInfo(): boolean {
    return this.updateOptions.isJnjConfidentialInfo;
  }

  public get isReadOnly(): boolean {
    return this.updateOptions.isReadOnly;
  }

  public get selectedMultiChoices(): string[] {
    const formControl = this.parentForm.get(this.propertyName);
    const selectedChoices = this.parseMultipleChoices(
      formControl.value?.toString(),
      ','
    );
    return selectedChoices;
  }

  public get relatedPropertyNames(): string[] {
    return this.getRelatedPropertyNamesFor(this.propertyName);
  }

  public get dynamicChoices(): ReferenceValue[] {
    switch (this.updateOptions.dynamicChoiceKey) {
      case DynamicChoiceKey.LeadershipDiversity:
        return this.getDeiDiversityChoices(false);
      case DynamicChoiceKey.BoardAdvisorDiversity:
        return this.getDeiDiversityChoices(true);
      case DynamicChoiceKey.RDStage:
        return this._deploymentContext.referenceValueData.rdStagesBySector[
          this.company.primarySector
        ];
      default:
        return null;
    }
  }

  private getDeiDiversityChoices(forBoardAdvisor: boolean): EditItemChoice[] {
    const options = this.getDiversityOptionsForCountry(
      this.company.countryForDeiReporting
    );

    if (!options) {
      return;
    }

    // This set will be treated like EditItemChoice objects and hence we need
    // copies to avoid unexpected shared state.
    const choices = _.clone(options);

    // Exclude choices that are specific to Board of Advisor, unless this is for the board question
    return choices.filter(
      (choice) => !choice.isOnlyForBoardAdvisor || forBoardAdvisor
    );
  }

  public parseMultipleChoices(
    separatedValueString: string,
    separator = ';'
  ): string[] {
    try {
      // Make sure they are sorted, so the diffs can reliably match sets with the same options.
      return separatedValueString?.split(separator)?.sort() ?? [];
    } catch {
      return [];
    }
  }

  public get valueList(): ReferenceValue[] | RoundsBySector {
    return (
      this.dynamicChoices ??
      this._deploymentContext.referenceValueData[
        this.updateOptions.referenceDataKey
      ] ?? [
        { label: '', value: '' },
        { label: this.Loc(LocalizedTextIds.Yes), value: 'Yes' },
        { label: this.Loc(LocalizedTextIds.No), value: 'No' },
      ]
    );
  }

  public logoSize: LogoSize = LogoSize.Large;
  @ViewChild('logoBase64chooser')
  public logoBase64chooser: ElementRef;
  public maxUploadLogoImageSize = maxUploadLogoImageSize;
  @Input()
  public parentForm: FormGroup;
  @Input()
  public propertyName: CompanyKey;
  public tagsToExclude: string[] = [];
  public tagsToPreserve: string[] = [];

  public get formControl(): AbstractControl {
    return this.parentForm.get(this.propertyName);
  }
  public currentLength = 0;

  public isShowingTooltip = false;
  public showTagsPickerModal = false;
  @ViewChild('tagsPickerModal')
  public tagsPickerModal: TagsPickerModalComponent;
  public tagsPickerMode = TagsPickerMode.CompanyUpdate;
  public tagUnderEdit = null;

  // Subjects
  public makeEditableSubject = new Subject<{
    propertyName: string;
    ev: MouseEvent;
  }>();
  public revertSubject = new Subject<string>();
  public revertMultipleSubject = new Subject<{
    propertyNames: string[];
    parent: string;
  }>();
  public revertRelatedItemsSubject = new Subject<string>();
  public tagReviewSubject = new Subject<TagReviewModification>();
  public constrainedValueChangeSubject = new Subject<string>();
  public multiChoiceValueChangedSubject = new Subject<MultiChoiceItemChange>();

  // public methods
  public bytesToHumanReadableDisplayString(
    nBytes: number,
    kSize: number = 1024
  ): string {
    const units = [
      this.Loc(LocalizedTextIds.Bytes),
      this.Loc(LocalizedTextIds.KB),
      this.Loc(LocalizedTextIds.MB),
      this.Loc(LocalizedTextIds.GB),
      this.Loc(LocalizedTextIds.TB),
      this.Loc(LocalizedTextIds.PB),
      this.Loc(LocalizedTextIds.EB),
      this.Loc(LocalizedTextIds.ZB),
      this.Loc(LocalizedTextIds.YB),
    ];

    let i = 0;
    for (i = 0; nBytes > kSize; ++i) {
      nBytes /= kSize;
    }

    return Math.max(nBytes, 0.1).toFixed(i ? 1 : 0) + ' ' + units[i];
  }

  public isCheckedMultiChoice(value: string): boolean {
    return !!this.selectedMultiChoices?.find((item) => item === value);
  }

  public stopEventPropagationDuringEditing(
    ev: MouseEvent,
    onlyIfEditing?: boolean
  ): void {
    if (!onlyIfEditing || this.isEditingProperty[this.propertyName]) {
      ev.stopPropagation();
    }
  }

  public isUpdateFieldSet(propertyName: string): boolean {
    return (
      this.reviewableUpdateFields[propertyName] &&
      this.reviewableUpdateFields[propertyName].isSet
    );
  }

  public arraysAreIdentical(a: string | [], b: string | []): boolean {
    a = a !== '' ? (a as []) : [];
    b = b !== '' ? (b as []) : [];
    return (
      a?.every((val, i) => val === b && b[i]) &&
      b?.every((val, i) => val === a && a[i])
    );
  }

  public itemIsDifferent(
    propertyName: string,
    companyToCompare: Company,
    isItemGroup?: boolean
  ): boolean {
    const updateOptions = getUpdateOptions(propertyName as CompanyKey);

    // FUTURE: It would be nice to avoid having to know the property names (e.g., 'tags' and 'companyContactTitle').
    if (
      this.propertyName === 'tags' ||
      ArrayConversions.includes(updateOptions?.updateConversion)
    ) {
      // NOTE: This assumes they are both sorted in the same order, which I believe they are when
      //       we first arrive. Any time tags are added we should ensure the tags remained ordered.
      return !_.isEqual(
        this.company[propertyName],
        companyToCompare[propertyName]
      );
    } else if (this.propertyName === 'companyContactTitle') {
      if (this.company.companyContact == null) {
        return false;
      }
      return areObjectsDifferent(
        this.company.companyContact.title,
        companyToCompare.companyContact.title
      );
    } else if (isItemGroup && this.valueList) {
      for (let i = 0; i < (this.valueList as ReferenceValue[]).length; i++) {
        const editItem = this.valueList[i];
        const isCompanyTruthy = !!this.company[editItem.value];
        const isCompareTruthy = !!companyToCompare[editItem.value];
        if (isCompanyTruthy !== isCompareTruthy) {
          return true;
        }
      }
    } else {
      // NOTE: This checks propertyName, not this.propertyName, because the group's label needs to be styled
      //       based on any difference in the group, while the individual items get checked by their propertyNames.
      //       Using this.propertyName (which would be 'firstTimeEntrepreneur') here would always check the group,
      //       so individual checkboxes wouldn't be styled individually.
      return areObjectsDifferent(
        this.company[propertyName],
        companyToCompare[propertyName]
      );
    }
  }

  public launchLogoChooser(ev: MouseEvent): void {
    this.stopEventPropagationDuringEditing(ev);
    this.logoBase64chooser.nativeElement.click();
  }

  public launchTagPickerModal(ev: MouseEvent, forEdit: boolean): void {
    this.makePropertyEditable(ev);
    this.tagsPickerMode = forEdit
      ? TagsPickerMode.CompanyUpdateEdit
      : TagsPickerMode.CompanyUpdate;
    this.showTagsPickerModal = true;
  }

  public onConstrainedValueChange(value: string): void {
    this.constrainedValueChangeSubject.next(value);
  }

  public onMultiChoiceItemChange(value: string): void {
    this.multiChoiceValueChangedSubject.next({
      propertyName: this.propertyName,
      value: value,
    });
  }

  public addTags(tagsToAdd: TagWithCount[]): void {
    this.showTagsPickerModal = false;

    if (this.tagUnderEdit) {
      // First remove the old custom tag if being replaced
      if (tagsToAdd.length > 0) {
        this.removeTagFromCompanyTags(this.tagUnderEdit);
      }
      this.tagUnderEdit = null;
    }

    for (const tagWithCounts of tagsToAdd) {
      const tag = tagWithCounts.tag;
      if (!this.tags.includes(tag)) {
        this.tags.push(tag);
      }
    }
  }

  public editTag(tag: string, ev: MouseEvent): void {
    this.stopEventPropagationDuringEditing(ev);
    if (this.selfUpdateMode === SelfUpdateMode.Review) {
      this._reviewEditsService.resetPropertyStatus(this.propertyName);
    }
    this.tagUnderEdit = tag;
    this.launchTagPickerModal(ev, true);
  }

  public removeTag(tag: string, ev: MouseEvent): void {
    this.stopEventPropagationDuringEditing(ev);
    this.removeTagFromCompanyTags(tag);
  }

  private removeTagFromCompanyTags(tag: string) {
    _.remove(this.company.tags, (aTag) => aTag === tag);
  }

  public toggleTagExclusion(tag: string, ev: MouseEvent): void {
    this.stopEventPropagationDuringEditing(ev);
    if (this.isExcludedTag(tag)) {
      _.remove(this.tagsToExclude, (aTag) => aTag === tag);
      this.tagReviewSubject.next({
        tag: tag,
        modificationType: TagReviewModificationType.Exclude,
        value: false,
      });
    } else {
      this.tagsToExclude.push(tag);
      this.tagReviewSubject.next({
        tag: tag,
        modificationType: TagReviewModificationType.Exclude,
        value: true,
      });
    }
    this._reviewEditsService.resetPropertyStatus(this.propertyName);
  }

  public toggleTagPreservation(tag: string, ev: MouseEvent): void {
    this.stopEventPropagationDuringEditing(ev);
    if (this.isPreservedTag(tag)) {
      _.remove(this.tagsToPreserve, (aTag) => aTag === tag);
      this.tagReviewSubject.next({
        tag: tag,
        modificationType: TagReviewModificationType.Preserve,
        value: false,
      });
    } else {
      this.tagsToPreserve.push(tag);
      this.tagReviewSubject.next({
        tag: tag,
        modificationType: TagReviewModificationType.Preserve,
        value: true,
      });
    }
    this._reviewEditsService.resetPropertyStatus(this.propertyName);
  }

  public isCustomTag(tag: string): boolean {
    return !this.existingTags?.some(
      (otherTag) => otherTag === tag.toLowerCase()
    );
  }

  public isExcludedTag(tag: string): boolean {
    return this.tagsToExclude.includes(tag);
  }

  public isPreservedTag(tag: string): boolean {
    return this.tagsToPreserve.includes(tag);
  }

  public makePropertyEditable(ev: MouseEvent): void {
    this.stopEventPropagationDuringEditing(ev);
    if (!this.isAvailableToEdit) {
      return;
    }
    if (this.type === EditItemType.Logo) {
      this.clearValidationErrors('logoBase64');
    }

    this.makeEditableSubject.next({ propertyName: this.propertyName, ev });
    this._reviewEditsService.resetPropertyStatus(this.propertyName);
  }

  public revertEdit(ev: MouseEvent): void {
    this.stopEventPropagationDuringEditing(ev);
    if (this.relatedPropertyNames?.length) {
      this.revertRelatedItems();
    } else if (this.type !== EditItemType.MultiBoolean) {
      this.revertItemEdit();
    } else {
      this.revertItems();
    }
  }

  public showValidationError(field: string, key: string): void {
    const ctrl = this.parentForm.get(field);
    const validationErrors: ValidationErrors = {};
    if (key) {
      validationErrors[key] = true;
    }
    ctrl.setErrors(validationErrors);
  }

  public clearValidationErrors(field: string): void {
    this.showValidationError(field, null);
  }

  public async onLogoUpdated(event: Event): Promise<void> {
    this._logger.info(event);
    const target = event.target as HTMLInputElement;
    const file = target.files[0];

    const recordError = (key: string) => {
      this.showValidationError('logoBase64', key);
    };

    if (!file) {
      // Probably Canceled
      recordError(null);
      return;
    }

    const fileType = file.type && file.type.toLowerCase();
    switch (fileType) {
      case 'image/png':
      case 'image/jpeg':
      case 'image/gif':
        break;
      default:
        // show error in UI
        recordError('badType');
        return;
    }

    if (file.size > maxUploadLogoImageSize) {
      // show error in UI
      recordError('tooLarge');
      return;
    }

    // Take down any old error
    recordError(null);

    const result = await this.toBase64(file).catch((e) => e);
    if (result instanceof Error) {
      this._logger.info('Error: ', result.message);
      return;
    }
    // Will be something like "data:image/jpeg;base64,/9j/4AAQ..." and we only want the image bytes
    const dataPos = result.indexOf(',') + 1;
    const data = result.substring(dataPos);
    this.parentForm.get('logoBase64').setValue(data);
  }

  public get required(): boolean {
    return (
      this.parentForm.get(this.propertyName)?.errors &&
      this.parentForm.get(this.propertyName)?.errors['required'] === true
    );
  }

  public propertyDecorationClass(isItemGroup?: boolean): string {
    let cssClass = '';
    if (
      this.selfUpdateMode === SelfUpdateMode.Review &&
      this.isReviewableUpdateFieldSet
    ) {
      cssClass = 'pending';
      cssClass += this.isEditingProperty[this.propertyName] ? ' editable' : '';
    } else if (
      this.selfUpdateMode === SelfUpdateMode.Edit &&
      this.isEditingProperty[this.propertyName]
    ) {
      cssClass = 'editable';
    } else if (!this.isAvailableToEdit) {
      cssClass = 'readonly';
    }
    const companyToCompare =
      this.selfUpdateMode === SelfUpdateMode.Edit
        ? this.companyBeforeAnyChanges
        : this.companyWithPending;
    if (
      this.itemIsDifferent(this.propertyName, companyToCompare, isItemGroup)
    ) {
      cssClass += ' different';
    }
    if (!isItemGroup && this.isPropertyDeclined) {
      cssClass += ' strikethrough';
    }
    if (
      !(
        this.selfUpdateMode === SelfUpdateMode.Review &&
        this.isReviewableUpdateFieldSet
      )
    ) {
      cssClass += ' not-for-review';
    }
    if (this.isJnjConfidentialInfo) {
      cssClass += ' jnj-confidential';
      if (this.showFlagIcon) {
        cssClass += ' with-flag';
      }
    }
    return cssClass;
  }

  public hasTooltip(): boolean {
    return !!this.tooltip;
  }

  public toggleTooltip(): void {
    this.isShowingTooltip = this.hasTooltip && !this.isShowingTooltip;
  }

  // private methods
  private revertItemEdit(): void {
    this.revertSubject.next(this.propertyName);
  }

  private revertItems(): void {
    const propertyNames = (this.valueList as ReferenceValue[]).map(
      (e) => e.value
    );
    this.revertMultipleSubject.next({
      propertyNames: propertyNames,
      parent: this.propertyName,
    });
  }

  private revertRelatedItems(): void {
    this.revertRelatedItemsSubject.next(this.propertyName);
  }

  private toBase64(file: Blob): Promise<string | ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  private lengthSubscription: Subscription;
  private get canLimitLength(): boolean {
    return (
      this.type === EditItemType.Multiline || this.type === EditItemType.Default
    );
  }
}
